import React from "react";
import "./index.css";
import logo from "./assets/logoico.png";
import background from "./assets/background.png";
import logogrande from "./assets/logo.png";
import scorpion1 from "./assets/scorpion1.jpg";
import scorpion2 from "./assets/scorpion2.jpg";
import scorpion3 from "./assets/scorpion3.jpg";
import desinfecciones from "./assets/desinfecciones.png";
import fumigaciones from "./assets/fumigaciones.png";
import industria from "./assets/industria.png";
import bacterias from "./assets/bacterias.png";
import mosquito from "./assets/mosquito.png";
import cuca from "./assets/cuca.png";
import organica from "./assets/organica.png";
import control from "./assets/control.png";
import comercio from "./assets/comercio.png";
import hogar from "./assets/hogar.png";
const services = [
  {
    name: 'Desinfecciones',
    img: `${desinfecciones}`,
  },
  {
    name: 'Fumigaciones',
    img: `${fumigaciones}`,
  },
  {
    name: 'Industria',
    img: `${industria}`,
  },
  {
    name: 'Bacterias - Virus - Gérmenes',
    img: `${bacterias}`,
  },
  {
    name: 'Mosquito - Pulga - Arañas - Hormigas',
    img: `${mosquito}`,
  },
  {
    name: 'Cucaracha - Ratas - Murciélago - Alacrán',
    img: `${cuca}`,
  },
  {
    name: 'Fumigación Orgánica',
    img: `${organica}`,
  },
  {
    name: 'Control de Plagas en General',
    img: `${control}`,
  },
  {
    name: 'Comercios',
    img: `${comercio}`,
  },
  {
    name: 'Hogares',
    img: `${hogar}`,
  },
];
const App = () => {
  return (
    <div className="font-sans">
      {/* Header */}
      <header className="bg-white shadow fixed top-0 w-full z-50 ">
        <div className="container mx-auto px-4 py-3 flex items-center justify-between">
          <div className="flex items-center">
            <img src={logo} alt="Logo" className="w-12 h-auto" />
            <span className="ml-4 text-lg font-bold">
              Audisio Desinfecciones
            </span>
          </div>
          <nav className="space-x-4">
            <a href="#Inicio" className="text-gray-700 hover:text-gray-900">
              Inicio
            </a>
            <a href="#Servicios" className="text-gray-700 hover:text-gray-900">
              Servicios
            </a>
            <a
              href="#SobreNosotros"
              className="text-gray-700 hover:text-gray-900"
            >
              Sobre Nosotros
            </a>
            <a href="#Contacto" className="text-gray-700 hover:text-gray-900">
              Contacto
            </a>
          </nav>
        </div>
      </header>

      {/* Inicio */}
      <section id="Inicio" className="pt-14">
        <img src={background} alt="Logo" className="w-full h-auto" />
      </section>
      <section id="Informacion" className="bg-gray-100 py-12">
        <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 items-center px-4">
          {/* Texto de la información */}
          <div className="bg-white p-8 rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-4">AUDISIO FUMIGACIONES</h2>
            <p className="text-gray-700 mb-4">
              Somos una compañía especializada en Control de Plagas,
              Fumigaciones, Desratización, Desinfección, Higiene y Saneamiento
              Ambiental, con el propósito de eliminar los factores de riesgo y
              hacer los ambientes más sanos y saludables para sus ocupantes.
            </p>
            <p className="text-gray-700">
              Con estos objetivos, hemos desarrollado distintas metodologías que
              garantizan la máxima efectividad de las fumigaciones y completa
              seguridad para personas, animales y bienes materiales.
            </p>
          </div>

          {/* Imagen del logo */}
          <div className="flex justify-center items-center">
            <img src={logogrande} alt="Logo" className="w-64 h-auto" />
          </div>
        </div>
      </section>

      {/* Servicios */}
      <section id="Servicios" className="bg-gray-100 py-12">
      <div className="container mx-auto">
        <h2 className="text-center text-2xl font-bold mb-6">Nuestros Servicios</h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
          {services.map((service, index) => (
            <div
              key={index}
              className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 text-center"
            >
              <img
                src={service.img}
                alt={service.name}
                className="w-16 h-16 mx-auto mb-4"
              />
              <p className="text-gray-700 font-medium">{service.name}</p>
            </div>
          ))}
        </div>
      </div>
    </section>

      {/* Sobre Nosotros */}
      <section id="SobreNosotros" className="py-10">
        <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div>
            <h3 className="text-xl font-bold mb-4">
              Cobertura de Nuestros Servicios
            </h3>
            <iframe
              src="https://www.google.com/maps/d/u/1/embed?mid=1tXumT-6rwArxhK9eKnsoR8em1CoLays&amp;ehbc=2E312F&amp;z=11"
              title="Mapa de Servicios"
              className="w-full h-[500px] border rounded-lg"
            />
          </div>
          <div className="bg-white p-6 rounded-lg shadow">
            <h3 className="text-xl font-bold mb-4">Tecnología UV</h3>
            <p className="mb-4">
              Trabajamos con luces ultravioleta para identificar plagas en
              espacios y combatirlas con mayor eficiencia. Muchos insectos
              emiten fluorescencia bajo luz UV.
            </p>
            <img
                src={scorpion1}
                alt="Escorpión UV 1"
                className="rounded-lg h-64 mx-auto pb-2"
              />
            <div className="grid grid-cols-2 gap-4">
              <img
                src={scorpion2}
                alt="Escorpión UV 1"
                className="rounded-lg"
              />
              <img
                src={scorpion3}
                alt="Escorpión UV 2"
                className="rounded-lg"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Contacto */}
      <section id="Contacto" className="bg-gray-800 text-white py-2">
        <div className="container mx-auto text-center">
          <h4 className="text-xl font-bold mb-4">Contacto</h4>
          <div className="space-y-2">
            <p>
              <strong>Teléfono:</strong> 3512104035
            </p>
            <p>
              <strong>Correo:</strong> audisiodesinfecciones@gmail.com
            </p>
            <p>
              <a
                href="https://api.whatsapp.com/send?phone=+3512104035
            </"
                target="_blank"
                rel="noopener noreferrer"
                className="text-green-400 underline"
              >
                WhatsApp
              </a>
            </p>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 text-white py-2">
        <div className="container mx-auto text-center">
          &copy; 2024 Audisio Desinfecciones. Todos los derechos reservados.
        </div>
      </footer>
    </div>
  );
};

export default App;
